import HeaderSlider from '../Slider/HeaderSlider';
import './Header.css';


const Header = () => {

return (
    <div className="headerWrapper" id="headerWrapper"> 
        <HeaderSlider />
    </div>
)
}

export default Header